import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Slideshow from "../../components/slideshow";
import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

function VCSection1() {
  const { vcScreenshot1, vcScreenshot2, vcScreenshot6 } = useStaticQuery(
    graphql`
      query {
        vcScreenshot1: file(relativePath: { eq: "vc-screenshot-1.jpg" }) {
          ...fluidImage
        }
        vcScreenshot2: file(relativePath: { eq: "vc-screenshot-2.jpg" }) {
          ...fluidImage
        }
        vcScreenshot6: file(relativePath: { eq: "vc-screenshot-6.jpg" }) {
          ...fluidImage
        }
      }
    `
  );

  return (
    <SplitHero>
      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            Virtual College.
          </Text>
          <Tag>2016 - 2018</Tag>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          I worked as a Frontend Developer within an agile sprint team during my
          second spell at Virtual College. I led the development of a component
          library shared across the company's websites and applications built
          using React. I also solely developed a WebGL experience that teaches
          the history of safeguarding children legislation in the UK.
          <TagGroup>
            <Tag>CSS</Tag>
            <Tag>HTML</Tag>
            <Tag>JavaScript</Tag>
            <Tag>React</Tag>
            <Tag>Redux</Tag>
            <Tag>Sass</Tag>
            <Tag>Styled Components</Tag>
            <Tag>WebGL</Tag>
          </TagGroup>
        </Text>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Slideshow>
            <Img
              fluid={vcScreenshot1.childImageSharp.fluid}
              alt="Virtual College Homepage"
            />
            <Img
              fluid={vcScreenshot2.childImageSharp.fluid}
              alt="Safeguarding Children WebGL Timeline"
            />
            <Img
              fluid={vcScreenshot6.childImageSharp.fluid}
              alt="Enable Dashboard"
            />
          </Slideshow>
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default VCSection1;
