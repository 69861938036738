import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Slideshow from "../../components/slideshow";
import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

function FreelanceSection() {
  const {
    freelanceScreenshot1,
    freelanceScreenshot2,
    freelanceScreenshot3,
  } = useStaticQuery(
    graphql`
      query {
        freelanceScreenshot1: file(
          relativePath: { eq: "freelance-screenshot-1.jpg" }
        ) {
          ...fluidImage
        }
        freelanceScreenshot2: file(
          relativePath: { eq: "freelance-screenshot-2.jpg" }
        ) {
          ...fluidImage
        }
        freelanceScreenshot3: file(
          relativePath: { eq: "freelance-screenshot-3.jpg" }
        ) {
          ...fluidImage
        }
      }
    `
  );

  return (
    <SplitHero>
      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            Freelance.
          </Text>
          <Tag>2008 - 2011</Tag>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          I freelanced for two UK-based agencies, Bream Team based in Kent and
          Nanthir LTD based in Powys producing websites and designs for local
          businesses, as well as some work for Charlton Athletic FC and
          Electronic Arts.
          <TagGroup>
            <Tag>HTML</Tag>
            <Tag>CSS</Tag>
            <Tag>JavaScript</Tag>
            <Tag>jQuery</Tag>
            <Tag>PHP</Tag>
            <Tag>Wordpress</Tag>
          </TagGroup>
        </Text>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Slideshow>
            <Img
              fluid={freelanceScreenshot1.childImageSharp.fluid}
              alt="John St John Productions"
            />
            <Img
              fluid={freelanceScreenshot2.childImageSharp.fluid}
              alt="Nanthir LTD"
            />
            <Img
              fluid={freelanceScreenshot3.childImageSharp.fluid}
              alt="Bradnant Equestrian Center"
            />
          </Slideshow>
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default FreelanceSection;
