import React from 'react';
import Helmet from 'react-helmet';

import Button from '../../components/button';
import Footer from '../../components/footer';
import Icon from '../../components/icon';
import Page from '../../components/page';
import TransitionLink from '../../components/transition-link';
import Text from '../../components/text';

import FFSection from './section-ff';
import FreelanceSection from './section-freelance';
import PaceSection from './section-pace';
import SkySection from './section-sky';
import VCSection1 from './section-vc-1';
import VCSection2 from './section-vc-2';

function ExperiencePage() {
  return (
    <Page>
      <Page.Container>
        <Helmet title="Steve Meredith - Experience"></Helmet>

        <Page.Title>
          <Text variant="h2" component="h1">
            Experience.
          </Text>
        </Page.Title>

        <Page.Section>
          <SkySection />
        </Page.Section>

        <Page.Section>
          <VCSection1 />
        </Page.Section>

        <Page.Section>
          <FFSection />
        </Page.Section>

        <Page.Section>
          <VCSection2 />
        </Page.Section>

        <Page.Section>
          <PaceSection />
        </Page.Section>

        <Page.Section>
          <FreelanceSection />
        </Page.Section>

        <Page.NextAction>
          <Button as={TransitionLink} to="/projects/">
            Projects <Icon icon="arrowRight" marginLeft={1} />
          </Button>
        </Page.NextAction>

        <Footer />
      </Page.Container>
    </Page>
  );
}

export default ExperiencePage;
