import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

function SkySection() {
  const { skyScreenshot1 } = useStaticQuery(
    graphql`
      query {
        skyScreenshot1: file(relativePath: { eq: "sky-screenshot-1.jpg" }) {
          ...fluidImage
        }
      }
    `
  );

  return (
    <SplitHero>
      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            Sky.
          </Text>
          <Tag primary>Current Position</Tag>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          I currently work as a Frontend Developer within the core team at Sky -
          The UK's largest pay-TV broadcaster with 12.5 million customers. I'm
          currently leading the development of a new library of compossible UI
          components that will be re-used throughout sky.com.
          <TagGroup>
            <Tag>Apollo Client</Tag>
            <Tag>CSS</Tag>
            <Tag>HTML</Tag>
            <Tag>GraphQL</Tag>
            <Tag>JavaScript</Tag>
            <Tag>Jest</Tag>
            <Tag>React Testing Library</Tag>
            <Tag>React</Tag>
            <Tag>Redux</Tag>
            <Tag>Storybook</Tag>
            <Tag>Styled Components</Tag>
            <Tag>Styled System</Tag>
          </TagGroup>
        </Text>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Img
            fluid={skyScreenshot1.childImageSharp.fluid}
            alt="Sky Homepage"
          />
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default SkySection;
