import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Slideshow from "../../components/slideshow";
import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

function VCSection2() {
  const {
    vcScreenshot3,
    vcScreenshot4,
    vcScreenshot5,
    vcScreenshot7,
  } = useStaticQuery(
    graphql`
      query {
        vcScreenshot7: file(relativePath: { eq: "vc-screenshot-7.jpg" }) {
          ...fluidImage
        }
        vcScreenshot3: file(relativePath: { eq: "vc-screenshot-3.jpg" }) {
          ...fluidImage
        }
        vcScreenshot4: file(relativePath: { eq: "vc-screenshot-4.jpg" }) {
          ...fluidImage
        }
        vcScreenshot5: file(relativePath: { eq: "vc-screenshot-5.jpg" }) {
          ...fluidImage
        }
      }
    `
  );

  return (
    <SplitHero>
      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            Virtual College.
          </Text>
          <Tag>2012 - 2016</Tag>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          I worked as a Frontend Developer &amp; Designer within an agile sprint
          team during my first spell at Virtual College. I led the front-end
          development and design on multiple websites and applications including
          Enable the company’s flagship Learning Management System (LMS).
          <TagGroup>
            <Tag>HTML</Tag>
            <Tag>CSS</Tag>
            <Tag>JavaScript</Tag>
            <Tag> C# Razer Syntax</Tag>
            <Tag>Angular</Tag>
            <Tag>Gulp</Tag>
            <Tag>Knockout.js</Tag>
            <Tag>PHP</Tag>
            <Tag>Sass</Tag>
            <Tag>Jekyll</Tag>
            <Tag>Wordpress</Tag>
          </TagGroup>
        </Text>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Slideshow>
            <Img
              fluid={vcScreenshot7.childImageSharp.fluid}
              alt="Enable Admin Panel"
            />
            <Img
              fluid={vcScreenshot3.childImageSharp.fluid}
              alt="Food Hygiene Product Page"
            />
            <Img
              fluid={vcScreenshot5.childImageSharp.fluid}
              alt="Responsive Course"
            />
            <Img
              fluid={vcScreenshot4.childImageSharp.fluid}
              alt="Safeguarding Children Homepage"
            />
          </Slideshow>
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default VCSection2;
