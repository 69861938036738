import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Slideshow from "../../components/slideshow";
import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

function FFSection() {
  const { ffScreenshot1, ffScreenshot2 } = useStaticQuery(
    graphql`
      query {
        ffScreenshot1: file(relativePath: { eq: "ff-screenshot-1.jpg" }) {
          ...fluidImage
        }
        ffScreenshot2: file(relativePath: { eq: "ff-screenshot-2.jpg" }) {
          ...fluidImage
        }
      }
    `
  );

  return (
    <SplitHero>
      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            Financial Force.
          </Text>
          <Tag>2016</Tag>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          I worked remotely as a Frontend Developer within the web development
          team with colleagues from Harrogate, New Hampshire and San Francisco.
          I led the front-end development of the main website. I also led the
          development of a standalone website to market the company’s appearance
          at Salesforce’s DreamForce event in San Fransisco.
          <TagGroup>
            <Tag>CSS</Tag>
            <Tag>HTML</Tag>
            <Tag>JavaScript</Tag>
            <Tag>PHP</Tag>
            <Tag>Sass</Tag>
          </TagGroup>
        </Text>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Slideshow>
            <Img
              fluid={ffScreenshot1.childImageSharp.fluid}
              alt="Noprofit Landing Page"
            />
            <Img fluid={ffScreenshot2.childImageSharp.fluid} alt="Homepage" />
          </Slideshow>
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default FFSection;
