import React, { useState, useEffect } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import * as Styles from './styles';

function Slideshow({ children, ...rest }) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      let nextSlideIndex = currentSlideIndex + 1 < children.length ? currentSlideIndex + 1 : 0;
      setCurrentSlideIndex(nextSlideIndex);
    }, 2000);
    return () => clearInterval(interval);
  }, [children, currentSlideIndex]);

  return (
    <Styles.Wrapper {...rest}>
      <TransitionGroup component={null}>
        {React.Children.map(children, (child, index) => {
          if (currentSlideIndex === index) {
            return (
              <Transition key={index} timeout={400}>
                {state => {
                  return <Styles.Slide state={state}>{child}</Styles.Slide>;
                }}
              </Transition>
            );
          }
        })}
      </TransitionGroup>
    </Styles.Wrapper>
  );
}

export default Slideshow;
