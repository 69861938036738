import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Slideshow from "../../components/slideshow";
import SplitHero from "../../components/split-hero";
import Tag, { TagGroup } from "../../components/tag";
import Text from "../../components/text";

function PaceSection() {
  const { paceScreenshot1, paceScreenshot2 } = useStaticQuery(
    graphql`
      query {
        paceScreenshot1: file(relativePath: { eq: "pace-screenshot-1.jpg" }) {
          ...fluidImage
        }
        paceScreenshot2: file(relativePath: { eq: "pace-screenshot-2.jpg" }) {
          ...fluidImage
        }
      }
    `
  );

  return (
    <SplitHero>
      <SplitHero.Content>
        <SplitHero.Heading>
          <Text variant="h3" component="h2" shade="light">
            Pace Commerce.
          </Text>
          <Tag>2011 - 2012</Tag>
        </SplitHero.Heading>

        <Text variant="p" shade="mid">
          I worked as a Website Designer and Developer within the E-Commerce
          team. I lead the front-end development and design of multiple
          E-Commerce websites, applications, and blogs.
          <TagGroup>
            <Tag>HTML</Tag>
            <Tag>CSS</Tag>
            <Tag>JavaScript</Tag>
            <Tag>jQuery</Tag>
            <Tag>PHP</Tag>
            <Tag>Wordpress</Tag>
          </TagGroup>
        </Text>
      </SplitHero.Content>

      <SplitHero.Media>
        <SplitHero.Media.Content>
          <Slideshow>
            <Img
              fluid={paceScreenshot1.childImageSharp.fluid}
              alt="Mastershoe"
            />
            <Img
              fluid={paceScreenshot2.childImageSharp.fluid}
              alt="Slane Cycles"
            />
          </Slideshow>
        </SplitHero.Media.Content>
      </SplitHero.Media>
    </SplitHero>
  );
}

export default PaceSection;
